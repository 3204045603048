import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { device } from 'src/device'
import { FormGroup } from 'src/elements/form/FormGroup'
import { Label } from 'src/elements/form/Label'
import { gql } from 'src/graphql'
import { withFragments } from 'src/libraries/graphql-fragments'
import { RotationIcon } from './RotationIcon'

const IconGroup = styled.div`
  display: flex;
  gap: 64px;

  @media ${device.mobile} {
    gap: 16px;
  }
`

const ROTATIONS = [0, 90, 180, 270]

export const RotationChanger = withFragments()(
  {
    screen: gql(/* GraphQL */ `
      fragment ScreenRotationFragment on Screen {
        id
        rotation
      }
    `),
  },
  function RotationChanger({ screen }) {
    const { t } = useTranslation()

    const [setRotation, setRotationResult] = useMutation(
      gql(/* GraphQL */ `
        mutation setRotation($rotation: Int!, $screenId: ID!) {
          setRotation(rotation: $rotation, screenId: $screenId) {
            id
            rotation
          }
        }
      `)
    )

    const saveRotation = useCallback(
      (rotation: number) => {
        setRotation({
          variables: {
            screenId: screen.id,
            rotation,
          },
        })
      },
      [setRotation, screen.id]
    )

    return (
      <FormGroup>
        <Label loading={setRotationResult.loading}>
          {t('screens.edit.settings.rotation.label')}
        </Label>
        <IconGroup>
          {ROTATIONS.map((rotation) => (
            <RotationIcon
              key={rotation}
              currentRotation={screen.rotation}
              rotation={rotation}
              onRotation={saveRotation}
            />
          ))}
        </IconGroup>
      </FormGroup>
    )
  }
)
