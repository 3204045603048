import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { device } from 'src/device'
import { SubTitle } from 'src/elements/SubTitle'
import { TitleH2 } from 'src/elements/TitleH2'
import { gql } from 'src/graphql'
import { BackIcon } from 'src/layout/BackIcon'
import { NavigationSlotContent } from 'src/layout/NavigationSlot'
import { Details } from './Details'

const Header = styled.div`
  margin-bottom: 60px;
`

const StyledBackIcon = styled(BackIcon)`
  display: block;

  @media ${device.mobile} {
    display: none;
  }
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
`

const ContentWrapper = styled.div`
  width: 75%;

  @media ${device.tablet} {
    width: 100%;
  }
`

export function ScreenPage() {
  const { t } = useTranslation()

  const { id } = useParams<'id'>()

  if (id === undefined) {
    throw new Error('Screen ID is required')
  }

  const screen = useQuery(
    gql(/* GraphQL */ `
      query Screen($id: ID!) {
        screen(id: $id) {
          name
          ...ScreenDetailsFragment
        }
      }
    `),
    { variables: { id } }
  )

  return (
    <div>
      <NavigationSlotContent>
        <StyledBackIcon path="/screens" title={t('routes.screens')} />
      </NavigationSlotContent>
      <Header>
        <TitleH2>{t('screens.edit.title')}</TitleH2>
        <SubTitle>{screen.data?.screen.name}</SubTitle>
      </Header>
      <ContentContainer>
        <ContentWrapper>
          {screen.loading ? (
            <div>Loading...</div>
          ) : screen.error ? (
            <div>Error: {screen.error.message}</div>
          ) : (
            screen.data !== undefined && <Details screen={screen.data.screen} />
          )}
        </ContentWrapper>
      </ContentContainer>
    </div>
  )
}
