import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box } from 'src/elements/Box'
import { gql } from 'src/graphql'
import { withFragments } from 'src/libraries/graphql-fragments'
import { Section } from 'src/playlists/edit/Section'
import { Name } from './Name'
import { PlaylistSelector } from './PlaylistSelector'
import { RotationChanger } from './RotationChanger'

const StyledSection = styled(Section)`
  margin-bottom: 60px;
`

export const Details = withFragments()(
  {
    screen: gql(/* GraphQL */ `
      fragment ScreenDetailsFragment on Screen {
        ...ScreenNameFragment
        ...ScreenPlaylistSelectorFragment
        ...ScreenRotationFragment
      }
    `),
  },
  function Details({ screen }) {
    const { t } = useTranslation()

    return (
      <>
        <StyledSection
          title={t('screens.edit.settings.title')}
          data-test="settings"
        >
          <Box>
            <Name screen={screen} />
            <PlaylistSelector screen={screen} />
            <RotationChanger screen={screen} />
          </Box>
        </StyledSection>
      </>
    )
  }
)
